import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BackgroundImage from "gatsby-background-image-es5"

const BackgroundImageWrapper = ({ children, hero, position, height }) => {
  const { hero1, hero2, hero3, hero4, hero5, hero6 } = useStaticQuery(
    graphql`
      query {
        hero1: file(relativePath: { eq: "hero1.jpeg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        hero2: file(relativePath: { eq: "hero2.jpeg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        hero3: file(relativePath: { eq: "hero3.jpeg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        hero4: file(relativePath: { eq: "hero4.jpeg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        hero5: file(relativePath: { eq: "hero5.jpeg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        hero6: file(relativePath: { eq: "hero6.jpeg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  )

  const imgToShow = hero === 'hero1' ? hero1 : hero === 'hero2' ? hero2 : hero === 'hero3' ? hero3 : hero === 'hero4' ? hero4 : hero === 'hero5' ? hero5 : hero === 'hero6' ? hero6 : hero;


  return (
    <BackgroundImage
      Tag="section"
      style={{ height: height, marginTop: '70px', backgroundPosition: position }}
      fluid={imgToShow.childImageSharp.fluid}
      alt="hero image"
    >
      {children}
    </BackgroundImage>
  )
}

export default BackgroundImageWrapper
